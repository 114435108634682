import React from 'react'
import Featured from '../general/Featured'
import FeaturedAssetOwners from '../general/FeaturedAssetOwners'
import FloatingContainer from '../floats/FloatingContainer'
import FloatingShape from '../floats/FloatingShape'

const FeaturedSection = props => {

    const { className, page = "other" } = props;
    const showhomepageFeatured = page === "asset owners" ? false : true;
    return (
        <div className={className + " relative z-10"}>
            <div>
                <FloatingContainer
                    className="w-1/2 left-1/4 left-0 "
                    height={'300px'}
                    style={{top: '-50%'}}
                    speed={10000}
                    floatSpeed={"custom"}
                    // floatStyle="linear"
                    floatType="drift"
                >

                    <FloatingShape
                        height={110}
                        width={110}
                    />
                </FloatingContainer>
            </div>

            {showhomepageFeatured && <div className="relative px-0">
                <Featured {...props} />
            </div>}
            {!showhomepageFeatured && <div className="relative px-0">
                <FeaturedAssetOwners {...props} />
            </div>}
        </div>

    )
}

export default FeaturedSection
